import React from 'react';

const AboutInformation = () => {
  return (
    <div className="information-container" data-v-7bfaf395="" data-v-6192361e="">
        <div data-v-7bfaf395="" className="information-item left">
            <h2 data-v-7bfaf395="" className="info-title">Introducing LQ MEDIA</h2> 
            <p data-v-7bfaf395="" className="info-text">
              We are the LQ music media, production and distribution company
              Media and is a music distributor (Music Distribution).
                <br data-v-7bfaf395=""/> <br data-v-7bfaf395=""/>
                Currently, we are providing products, services, and solutions
                360 for our partners including: manufacturing, distribution, marketing,
                mining and trade, optimization, management and career guidance
                (music style and image), helping to create a peaceful music community
                full of potential and opportunities.
                <br data-v-7bfaf395=""/> <br data-v-7bfaf395=""/>
                Our mission is “to make music easy for everyone
                easily spread and reach the audience", and we build prices
                His policy revolves around this statement.
            </p>
        </div> 
        <div data-v-7bfaf395="" className="information-item right">
            <img data-v-7bfaf395="" src="/_nuxt/img/image1.f497a80.png" alt="" className="info-img"/>
        </div>
    </div>
  );
};

export default AboutInformation;