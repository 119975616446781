import React from "react";

const Platform = () => {
  return (
    <div className="platform-container" data-v-b7995c84>
      <div className="line" data-v-b7995c84>
        <img alt="" src="/_nuxt/img/Divider.64e9d59.png" data-v-b7995c84 />
      </div>
      <div className="platform-content" data-v-b7995c84>
        <h2 data-v-b7995c84>
        Let your music products flood the platforms
        </h2>
      </div>
      <div className="platform-data" data-v-b7995c84></div>
      <div className="container" data-v-b7995c84>
        <div
          className="row row-cols-2 row-cols-md-3 row-cols-lg-6 g-2 g-lg-3"
          data-v-b7995c84
        >
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/Spotify.png"
                alt="Spotify"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/Apple Music.png"
                alt="Apple Music"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/Fb.png"
                alt="Facebook"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/insta.png"
                alt="Instagram"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/Tiktok.png"
                alt="Tiktok"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/Youtube.png"
                alt="Youtube Music"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/Zing Mp3.png"
                alt="Zing Mp3"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/NCT.png"
                alt="Nhaccuatui"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/Youtube 2.png"
                alt="Youtube"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/tiktok music.png"
                alt="Tiktok Music"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/anghami.png"
                alt="Anghami"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/audiomack.png"
                alt="Audiomack"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/melon.png"
                alt="Melon"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/Amazon music.png"
                alt="Amazon Music"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/awa.png"
                alt="Awa"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/Boomplay.png"
                alt="Boomplay"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/iheartradio.png"
                alt="IheartRadio"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/capcut.png"
                alt="Capcut"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/deezer.png"
                alt="Deezer"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/kkbox.png"
                alt="Kkbox"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/joox.png"
                alt="Joox"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/vevo.png"
                alt="Vevo"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/nuuday.png"
                alt="Nuuday"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/napster.png"
                alt="Napster"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/tidal.png"
                alt="Tidal"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/pandora.png"
                alt="Pandora"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/soundcloud.png"
                alt="Soundcloud"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/resso.png"
                alt="Resso"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/sharam.png"
                alt="Sharam"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/Snap.png"
                alt="Snap"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/zuto.png"
                alt="zuto"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/itune.png"
                alt="Itunes"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/peloton.png"
                alt="Peloton"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/claromusic.png"
                alt="Claro musica"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/doying.png"
                alt="Doying"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/7digital.png"
                alt="7digital"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/kanijan.png"
                alt="Kanjian"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/audiblemagic.png"
                alt="Audible Magic"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/imusica.png"
                alt="Imusica"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/jaxsta.png"
                alt="Jaxsta"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/qobuz.png"
                alt="Qobuz"
                data-v-b7995c84
              />
            </div>
          </div>
          <div className="col" data-v-b7995c84>
            <div className="p-3" data-v-b7995c84>
              <img
                src="/img/platform/quachanh.png"
                alt="quachanh"
                data-v-b7995c84
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform;
