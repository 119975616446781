import React from "react";

const Footer = () => {
  return (
    <footer className="footer-container" data-v-dc8f0f50>
      <div className="container-fluid" data-v-dc8f0f50>
        <div className="container" data-v-dc8f0f50>
          <div className="row footer-body" data-v-dc8f0f50>
            <div className="col-md-3 company-info" data-v-dc8f0f50>
              <div className="logo-container" data-v-dc8f0f50>
                <a
                  href="/"
                  aria-current="page"
                  className="nuxt-link-exact-active nuxt-link-active"
                  data-v-dc8f0f50
                >
                  <img
                    src="/_nuxt/img/Logo_web.c707601.png"
                    alt="LQ MEDIA"
                    className="footer-logo navbar-brand"
                    data-v-dc8f0f50
                  />
                </a>
              </div>
              <div className="row" data-v-dc8f0f50>
                <span className="col" data-v-dc8f0f50>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFWSURBVHgB5ZTNUcMwEIWflAyBm0twB3Y6CFeGg80M5wwdJBXgDnAHpAIwh4QjpgImFeASfGM8Q7Ss4p8Y26MkzonhXaz1rj5JuysB/06ibjyTZ11gMCWQe8hkgooz4MUXUdoCvpJnE+QbD20cJUoy0CVDE23J3Wp9YNs92SOIx9LaApd06/aDVdDJkjy3Agoou+bV+Uj2QyipxwkMnBqQrJ0DYQY15mFkoEWctzHHLpoO2VoXCM4gZ1fiyecqBm2/CrRPx+jYpn+IDvHK9yu6cXin8xHUmtd94N/WN9TdBohX5C/YnnbN7QQW8rh6rm4J5CnAOUOHkB8wFNAERNESnwIi5uOl3PATvVPTjD3AXAXoIMl8gkhxoiRUmn9ZGTYxTtQXaF0Bi8sdoqcUVFje5V+vDbeKbo8ZjpCGXYtoXtqiGaBfHa6qoyCN1dQ542O+15+uv6EflulzLH1D/j0AAAAASUVORK5CYII="
                    alt="email"
                    data-v-dc8f0f50
                  />
                  <p data-v-dc8f0f50>support@lqmedia.vn</p>
                </span>
              </div>
              <div className="row" data-v-dc8f0f50>
                <span className="col" data-v-dc8f0f50>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFgSURBVHgBzZNdTsJAEMf/sxJ5hCOUG8gNfIaoLfFduQE3UE8AnkAuIK0m4qPcQI/QG4hvQmDH2daWtlLtkpjwT5r9mM1vpvMB7Lsoe3hi90hBnWnohy4Fb9hBKfCRXbcG5Sdnhm7vAlXJRmDDvCfyfXabsFQEfObzY1mcvImcOnAFS31HqJ0S88DkFbZAjYOyXM2X8sFCaVGm3HuXJZezFbR3SkEAC6kMeZQ1sES2BmawVAr8hL5F5vfEQbMOdQdLpUCPAoHpfsHuTtkdwkJUvJBcGsAgf6tHC+AmdmomqncpfTrv0H3wJzCGeq9iKrQLh4yaR1iJM7qIboDxEto4Cn8Fmgmpg15+QreJwzW4f0LBrBS4gUZFcVFByeyrsgcmXx2aePLwGhUkOW3EawXFRYjm2tn+gsMO+S2zUxV46NJkLNG2pAjSVlwYU54twO1NpDvI5PcQqiEV/kha6d/0BSgfeif6JMwXAAAAAElFTkSuQmCC"
                    alt="email"
                    data-v-dc8f0f50
                  />
                  <p data-v-dc8f0f50>+84-919.978.560</p>
                </span>
              </div>
              <div className="row" data-v-dc8f0f50>
                <span className="col" data-v-dc8f0f50>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFtSURBVHgBzZRNUsJAEIVfT1HikhuQI+gN4hLYjFS5Bo/gCQgnUE8AXECyQZfiCcQbxBuwFC3S9pCKlZn8WlZZ9CbTM51vut/0DHDsRlWLS9adUygtw67xGfS2w35zSWH0K6ABnYACAo3E7bjrDMw/Ed8IeFsLfGLtMdSzDD1UGkc78IWbrcrv3gR2yMVrg2burAVc8XDcDPYD9R9Zj0qBKtHMNaPTnWgTyDcqgI6zXivrMNh3w3eIz1OdRN+FSPIK66DIL8zQHAZyxuus6D0Zy8lv3CiRqpsD9pIft3bGdGZayJ5jzwUO6OE9HbecjDbZEkS3ThtqtuKrqcLXVsq9Re7QeJ31LKCUs5am9e0foAl7zfkOS4HzrGdFfSC+h1N2tXHUp3BRCkyuUnyNxsaBO5OrQ3YMGXGAOpTEuNkZK31tVqwnBBWUwQYUTovWKp+vImgVrBaYQIdjCZrgcDtY92n5gr+aaW63wf/NvgHp4YRQSzI74gAAAABJRU5ErkJggg=="
                    alt="email"
                    data-v-dc8f0f50
                  />
                  <p data-v-dc8f0f50>Thanh Vinh, Thanh Trach, Bo Trach, Quang Binh</p>
                </span>
              </div>
            </div>
            <div className="col-md-8 categories" data-v-dc8f0f50>
              <div data-v-dc8f0f50>
                <div className="row" data-v-dc8f0f50>
                  <div className="col-4 category" data-v-dc8f0f50>
                    <a
                      href="/"
                      aria-current="page"
                      className="nav-link nuxt-link-exact-active nuxt-link-active"
                      data-v-dc8f0f50
                    >
                      Home
                    </a>
                  </div>
                  <div className="col-4 category" data-v-dc8f0f50>
                    <a href="/about" className="nav-link" data-v-dc8f0f50>
                      Introduce
                    </a>
                  </div>
                  <div className="col-4 category" data-v-dc8f0f50>
                    <a href="/services" className="nav-link" data-v-dc8f0f50>
                      Service
                    </a>
                  </div>
                </div>
              </div>
              <div data-v-dc8f0f50></div>
              <div data-v-dc8f0f50></div>
              <div data-v-dc8f0f50>
                <div className="row" data-v-dc8f0f50>
                  <div className="col-4 category" data-v-dc8f0f50>
                    <a href="/products" className="nav-link" data-v-dc8f0f50>
                      Products
                    </a>
                  </div>
                  <div className="col-4 category" data-v-dc8f0f50>
                    <a href="/allocation" className="nav-link" data-v-dc8f0f50>
                      Allocation
                    </a>
                  </div>
                  <div className="col-4 category" data-v-dc8f0f50>
                    <a href="/artists" className="nav-link" data-v-dc8f0f50>
                      Artists
                    </a>
                  </div>
                </div>
              </div>
              <div data-v-dc8f0f50></div>
              <div data-v-dc8f0f50></div>
              <div data-v-dc8f0f50>
                <div className="row" data-v-dc8f0f50>
                  <div className="col-4 category" data-v-dc8f0f50></div>
                  <div className="col-4 category" data-v-dc8f0f50></div>
                </div>
              </div>
              <div data-v-dc8f0f50></div>
            </div>
          </div>
          <div id="break-line-grandient" className="row" data-v-dc8f0f50></div>
          <div className="row break-line" data-v-dc8f0f50></div>
          <div className="row signature" data-v-dc8f0f50>
            <div className="col-lg-9 col-md-8 copyright" data-v-dc8f0f50>
              © Copyright LQ MEDIA.
            </div>
            <div
              className="col-lg-3 col-md-4 col-sm-6 social-network"
              data-v-dc8f0f50
            >
              <div className="row" data-v-dc8f0f50>
                <div className="col social-icon" data-v-dc8f0f50>
                  <span className="col" data-v-dc8f0f50>
                    <a
                      href="https://www.facebook.com/LQmedia73"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-v-dc8f0f50
                    >
                      <img
                        src="/_nuxt/img/bordered-icon-facebook.667a92a.png"
                        alt="facebook"
                        data-v-dc8f0f50
                      />
                    </a>
                  </span>
                </div>
                <div className="col social-icon" data-v-dc8f0f50>
                  <span className="col" data-v-dc8f0f50>
                    <a
                      href="https://www.youtube.com/channel/UCREwXigjpvbv_GYUrd6QVtg"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-v-dc8f0f50
                    >
                      <img
                        src="/_nuxt/img/bordered-icon-youtube.c066420.png"
                        alt="youtube"
                        data-v-dc8f0f50
                      />
                    </a>
                  </span>
                </div>
                <div className="col social-icon" data-v-dc8f0f50>
                  <span className="col" data-v-dc8f0f50>
                    <a
                      href="https://www.tiktok.com/@lqmedia.vn"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-v-dc8f0f50
                    >
                      <img
                        src="/_nuxt/img/bordered-icon-tiktok.45eb4a7.png"
                        alt="tiktok"
                        data-v-dc8f0f50
                      />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
