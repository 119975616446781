import React from "react";

const Landing = () => {
  return (
    <div className="landing-container" data-v-19907d8e>
        <div className="landing-item left" data-v-19907d8e>
            <h1 className="title-landing" data-v-19907d8e>
            Spread your music more easily with &nbsp;
            <span className="mark-up" data-v-19907d8e>
                LQ MEDIA
            </span>
            </h1>
            <br data-v-19907d8e />
            <p className="text-landing" data-v-19907d8e>
            Accompanying and supporting musicians, artists, bands and record labels to achieve greater goals and achievements with their products on digital music platforms.
            </p>
        </div>
        <div className="landing-item right" data-v-19907d8e>
            <div data-v-19907d8e>
            <img
                src="/_nuxt/img/Hero Image.1c70e0a.png"
                alt="LQ MEDIA"
                className="landing-img"
                data-v-19907d8e
            />
            </div>
        </div>
    </div>
  );
};

export default Landing;
