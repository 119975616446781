import React from 'react';

const AboutRelease = () => {
  return (
    <div data-v-15499593="" data-v-6192361e="" className="release-containter">
        <div data-v-15499593="" className="line">
            <img data-v-15499593="" src="/_nuxt/img/Divider.64e9d59.png" alt=""/>
        </div> 
        <h2 data-v-15499593="" className="release-title">Product distribution and release policy</h2> 
        <div data-v-15499593="" className="item-container">
            <div data-v-15499593="" className="release-item text-item">
                <ul data-v-15499593="" className="item-col">
                    <li data-v-15499593="" className="item-row">
                        <div data-v-15499593="" className="music-note">
                            <img data-v-15499593="" src="/_nuxt/img/ic-musicnote.59fd4a8.png" alt=""/>
                        </div> 
                        <p data-v-15499593="" className="release-text">
                            Distributing products to more than
                            <span data-v-15499593="" className="mark-up">200 digital music <br data-v-15499593=""/>
                            platforms</span>
                            worldwide such as: Spotify, Apple <br data-v-15499593=""/>
                            Music, Itunes, Facebook, Tiktok, Youtube Music, <br data-v-15499593=""/>
                            Zing Mp3, Nhaccuatui,...
                        </p>
                    </li> 
                    <li data-v-15499593="" className="item-row">
                        <div data-v-15499593="" className="music-note">
                            <img data-v-15499593="" src="/_nuxt/img/ic-musicnote.59fd4a8.png" alt=""/>
                        </div> 
                        <p data-v-15499593="" className="release-text">
                            Fast release speed, appearance on <br data-v-15499593=""/>
                            platforms <span data-v-15499593="" className="mark-up">within 48 hours</span>.
                        </p>
                    </li>
                </ul> 
                <a data-v-15499593="" href="/lien-he" className="set-effect">
                    <button data-v-15499593="" type="button" className="btn btn-release">
                    Contact now
                    </button>
                </a>
            </div> 
            <div data-v-15499593="" className="release-item img-item">
                <div data-v-15499593="">
                    <img data-v-15499593="" src="/_nuxt/img/Group 1.c5b72b6.png" alt="" className="release-img"/>
                </div> 
                <div data-v-15499593="" className="flickity-enabled is-draggable" tabIndex="0">
                    <div className="flickity-viewport" style={{ height: "30px", touchAction: "pan-y"}}>
                        <div className="flickity-slider" style={{ left: "0px", transform: "translate3d(-487.91%, 0px, 0px)"}}>
                            <div data-v-15499593="" className="carousel-cell" style={{position: "absolute", left: "0px", transform: "translateX(0%)"}} aria-hidden="true">
                                <div data-v-15499593="" className="carousel-img">
                                    <img data-v-15499593="" src="/img/platform/Spotify.png" alt=""/>
                                </div>
                            </div>
                            <div data-v-15499593="" className="carousel-img">
                                <img data-v-15499593="" src="/img/platform/siriusxm.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div data-v-15499593="" className="item-container row-reverse-container">
            <div data-v-15499593="" className="release-item text-item">
                <ul data-v-15499593="" className="item-col">
                    <li data-v-15499593="" className="item-row">
                        <div data-v-15499593="" className="music-note">
                            <img data-v-15499593="" src="/_nuxt/img/ic-musicnote.59fd4a8.png" alt=""/>
                        </div> 
                        <p data-v-15499593="" className="release-text">Youtube Content ID service is free.</p>
                    </li> 
                    <li data-v-15499593="" className="item-row">
                        <div data-v-15499593="" className="music-note">
                            <img data-v-15499593="" src="/_nuxt/img/ic-musicnote.59fd4a8.png" alt=""/>
                        </div> 
                        <p data-v-15499593="" className="release-text">
                        Provides dashboards for partners to manage <br data-v-15499593=""/>
                        their release portfolio, analyze daily data, <br data-v-15499593=""/>
                        and report revenue.
                        </p>
                    </li> 
                    <li data-v-15499593="" className="item-row">
                        <div data-v-15499593="" className="music-note">
                            <img data-v-15499593="" src="/_nuxt/img/ic-musicnote.59fd4a8.png" alt=""/>
                        </div> 
                        <p data-v-15499593="" className="release-text">
                        Provide individual artist management accounts for <br data-v-15499593=""/>
                        bands, record labels, and publishing companies.
                        </p>
                    </li>
                </ul> 
                <a data-v-15499593="" href="/lien-he" className="set-effect">
                    <button data-v-15499593="" type="button" className="btn btn-release">
                        Contact now
                    </button>
                </a>
            </div> 
            <div data-v-15499593="" className="release-item img-item">
                <div data-v-15499593="">
                    <img data-v-15499593="" src="/_nuxt/img/Frame 1261152795.2b706a4.png" alt="" className="release-img"/>
                </div>
            </div>
        </div> 
        <div data-v-15499593="" className="item-container">
            <div data-v-15499593="" className="release-item text-item">
                <ul data-v-15499593="" className="item-col">
                    <li data-v-15499593="" className="item-row">
                        <div data-v-15499593="" className="music-note">
                            <img data-v-15499593="" src="/_nuxt/img/ic-musicnote.59fd4a8.png" alt=""/>
                        </div> 
                        <p data-v-15499593="" className="release-text">Monthly revenue payment.</p>
                    </li> 
                    <li data-v-15499593="" className="item-row">
                        <div data-v-15499593="" className="music-note">
                            <img data-v-15499593="" src="/_nuxt/img/ic-musicnote.59fd4a8.png" alt=""/>
                        </div> 
                        <p data-v-15499593="" className="release-text">
                        Support revenue advances with potential products.
                        </p>
                    </li> 
                    <li data-v-15499593="" className="item-row">
                        <div data-v-15499593="" className="music-note">
                            <img data-v-15499593="" src="/_nuxt/img/ic-musicnote.59fd4a8.png" alt=""/>
                        </div> 
                        <p data-v-15499593="" className="release-text">
                        Supports artist integration on several platforms such as: Spotify, <br data-v-15499593=""/>
                            Apple Music Youtube, Amazon Music.
                        </p>
                    </li>
                </ul> 
                <a data-v-15499593="" href="/lien-he" className="set-effect">
                    <button data-v-15499593="" type="button" className="btn btn-release">
                    Contact now
                    </button>
                </a>
            </div> 
            <div data-v-15499593="" className="release-item img-item">
                <div data-v-15499593="">
                    <img data-v-15499593="" src="/_nuxt/img/Frame 1261152802.da95e20.png" alt="" className="release-img"/>
                </div>
            </div>
        </div>
    </div>
  );
};

export default AboutRelease;