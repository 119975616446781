import React from 'react';

const AboutSolution = () => {
  return (
    <div data-v-9efc35fa="" data-v-6192361e="" className="solution-container">
        <div data-v-9efc35fa="" className="line">
            <img data-v-9efc35fa="" src="/_nuxt/img/Divider.64e9d59.png" alt=""/>
        </div> 
        <h2 data-v-9efc35fa="" className="solution-title">Production solutions, product orientation</h2> 
        <div data-v-9efc35fa="" className="solution-flex">
            <div data-v-9efc35fa="" className="solution-item left">
                <ul data-v-9efc35fa="" className="item-col">
                    <li data-v-9efc35fa="" className="item-row">
                        <div data-v-9efc35fa="" className="music-note">
                            <img data-v-9efc35fa="" src="/_nuxt/img/ic-musicnote.59fd4a8.png" alt=""/>
                        </div> 
                        <p data-v-9efc35fa="" className="solution-text">
                        Providing product production solutions including music production
                            <br data-v-9efc35fa=""/>studio, recording studio, music video editing crew, professional 
                            <br data-v-9efc35fa=""/>images equipped with modern equipment.
                        </p>
                    </li> 
                    <li data-v-9efc35fa="" className="item-row">
                        <div data-v-9efc35fa="" className="music-note">
                            <img data-v-9efc35fa="" src="/_nuxt/img/ic-musicnote.59fd4a8.png" alt=""/>
                        </div> 
                        <p data-v-9efc35fa="" className="solution-text">
                            A team of experts in many fields such as Composer,  Songwriter, <br data-v-9efc35fa=""/>
                            Producer,... supports consulting and perfecting products to help 
                            <br data-v-9efc35fa=""/>
                            improve product quality.
                        </p>
                    </li> 
                    <li data-v-9efc35fa="" className="item-row">
                        <div data-v-9efc35fa="" className="music-note">
                            <img data-v-9efc35fa="" src="/_nuxt/img/ic-musicnote.59fd4a8.png" alt=""/>
                        </div> 
                        <p data-v-9efc35fa="" className="solution-text">
                        Search for sponsors and partners to cooperate with
                            <br data-v-9efc35fa=""/>
                            potential products.
                        </p>
                    </li> 
                    <li data-v-9efc35fa="" className="item-row">
                        <div data-v-9efc35fa="" className="music-note">
                            <img data-v-9efc35fa="" src="/_nuxt/img/ic-musicnote.59fd4a8.png" alt=""/>
                        </div> 
                        <p data-v-9efc35fa="" className="solution-text">
                        Consulting and product orientation, choosing appropriate elements and styles, <br data-v-9efc35fa=""/>
                        giving ideas to help partners improve product quality in accordance
                            <br data-v-9efc35fa=""/>
                            with development strategies.
                        </p>
                    </li>
                </ul>
            </div> 
            <div data-v-9efc35fa="" className="solution-item right">
                <img data-v-9efc35fa="" src="/_nuxt/img/illustration newsletter.0bdca0c.png" alt="" className="solution-img"/>
            </div>
        </div>
    </div>
  );
};

export default AboutSolution;