import React from 'react';

const AboutTechnology = () => {
  return (
    <div data-v-32bb2674="" data-v-6192361e="" className="technology-container">
        <div data-v-32bb2674="" className="line">
            <img data-v-32bb2674="" src="/_nuxt/img/Divider.64e9d59.png" alt=""/>
        </div> 
        <div data-v-32bb2674="" className="technology-content">
            <h2 data-v-32bb2674="">Communication policy, product marketing</h2>
        </div> 
        <div data-v-32bb2674="" className="container">
            <div data-v-32bb2674="" className="row row-cols-1 row-cols-lg-3 g-2 g-lg-3">
                <div data-v-32bb2674="" className="col">
                    <div data-v-32bb2674="" className="p-4 content-container">
                        <div data-v-32bb2674="" className="icon-content">
                            <img data-v-32bb2674="" src="/_nuxt/img/1.909bb2e.png" alt=""/>
                        </div> 
                        <div data-v-32bb2674="" className="data-content">
                            <p data-v-32bb2674="">
                            Providing communication solutions on multiple social network systems
                            Channels: Facebook, Youtube, Tiktok, Website,... of Terrace company
                            Media.
                            </p>
                        </div>
                    </div>
                </div> 
                <div data-v-32bb2674="" className="col">
                    <div data-v-32bb2674="" className="p-4 content-container">
                        <div data-v-32bb2674="" className="icon-content">
                            <img data-v-32bb2674="" src="/_nuxt/img/2.dc82406.png" alt=""/>
                        </div> 
                        <div data-v-32bb2674="" className="data-content">
                            <p data-v-32bb2674="">
                            Marketing services on platforms: Spotify, Apple Music,
                            Itunes, Zing Mp3, Nhaccuatui,...
                            </p>
                        </div>
                    </div>
                </div> 
                <div data-v-32bb2674="" className="col">
                    <div data-v-32bb2674="" className="p-4 content-container">
                        <div data-v-32bb2674="" className="icon-content">
                            <img data-v-32bb2674="" src="/_nuxt/img/3.4bbafcd.png" alt=""/>
                        </div> 
                        <div data-v-32bb2674="" className="data-content">
                            <p data-v-32bb2674="">
                            Support in creating media images and product achievement publications
                            product (if any)
                            </p>
                        </div>
                    </div>
                </div> 
                <div data-v-32bb2674="" className="col">
                    <div data-v-32bb2674="" className="p-4 content-container">
                        <div data-v-32bb2674="" className="icon-content">
                            <img data-v-32bb2674="" src="/_nuxt/img/4.a43aefd.png" alt=""/>
                        </div> 
                        <div data-v-32bb2674="" className="data-content">
                            <p data-v-32bb2674="">
                            Build Landing page for each product, integrating platforms
                            communication.
                            </p>
                        </div>
                    </div>
                </div> 
                <div data-v-32bb2674="" className="col">
                    <div data-v-32bb2674="" className="p-4 content-container">
                        <div data-v-32bb2674="" className="icon-content">
                            <img data-v-32bb2674="" src="/_nuxt/img/5.bff7a7e.png" alt=""/>
                        </div> 
                        <div data-v-32bb2674="" className="data-content">
                            <p data-v-32bb2674="">
                            Organize interviews, write blogs to report on products when preparing for release
                            eye.
                            </p>
                        </div>
                    </div>
                </div> 
                <div data-v-32bb2674="" className="col">
                    <div data-v-32bb2674="" className="p-4 content-container">
                        <div data-v-32bb2674="" className="icon-content">
                            <img data-v-32bb2674="" src="/_nuxt/img/6.f0ea820.png" alt=""/>
                        </div> 
                        <div data-v-32bb2674="" className="data-content">
                            <p data-v-32bb2674="">
                            Monitoring and analysis tools, regularly updating results
                            Evaluate the effectiveness of communication campaigns,...
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default AboutTechnology;