export const MUSIC_DATA = [
  {
    id: 1,
    image: "/img/music/nodt.jpg",
    name: "NGỰA Ô DUYÊN TÌNH",
    artist: "PHẠM SẮC LỆNH",
    url: "https://www.youtube.com/watch?v=_LnBcXOI2Gk",
    artist_url: "https://www.facebook.com/phamsaclenh97",
  },
  {
    id: 2,
    image: "/img/music/colieu.jpg",
    name: "CÔ LIÊU",
    artist: "PHẠM SẮC LỆNH",
    url: "https://www.youtube.com/watch?v=S042dZnqe0w",
    artist_url: "https://www.facebook.com/phamsaclenh97",
  },
  {
    id: 3,
    image: "/img/music/lnmd.jpg",
    name: "MÌNH LẠC NHAU MUÔN ĐỜI",
    artist: "PHẠM SẮC LỆNH",
    url: "https://www.youtube.com/watch?v=MAJGfBD4fUs",
    artist_url: "https://www.facebook.com/phamsaclenh97",
  },
  {
    id: 4,
    image: "/img/music/mmkce.jpg",
    name: "MAY MẮN KHI CÓ EM",
    artist: "ĐẠT VILLA",
    url: "https://www.youtube.com/watch?v=c5PhM-bDrkc",
    artist_url: "https://www.facebook.com/datvilla94",
  },
  {
    id: 5,
    image: "/img/music/sgcdal.jpg",
    name: "SÓNG GIÓ CỨ ĐỂ ANH LO",
    artist: "ĐẠT VILLA",
    url: "https://www.youtube.com/watch?v=4IfoEi7O_0Q",
    artist_url: "https://www.facebook.com/datvilla94",
  },
  {
    id: 6,
    image: "/img/music/dpma.jpg",
    name: "ĐƠN PHƯƠNG MÌNH ANH",
    artist: "LINH TUỔI THÌN",
    url: "https://www.youtube.com/watch?v=SNts5pPOwBk",
    artist_url: "https://www.facebook.com/LQmedia73",
  },
  {
    id: 7,
    image: "/img/music/bnctld.jpg",
    name: "BAO NHIÊU CHÂN THÀNH LÀ ĐỦ",
    artist: "HỒ NHẬT HUY",
    url: "https://www.youtube.com/watch?v=Nv0RiJWI44Q",
    artist_url: "https://www.facebook.com/LQmedia73",
  },
  {
    id: 8,
    image: "/img/music/taxn.jpg",
    name: "THANH ÂM XỨ NGƯỜI",
    artist: "THÙY LINH ĐẶNG",
    url: "https://www.youtube.com/watch?v=ifm9KhbN5hk",
    artist_url: "https://www.facebook.com/profile.php?id=100004646620626",
  },
];

export const MUSIC_TOP_DATA = [
  {
    id: 1,
    image: "/img/music/nodt.jpg",
    name: "NGỰA Ô DUYÊN TÌNH",
    artist: "PHẠM SẮC LỆNH",
    url: "https://www.youtube.com/watch?v=_LnBcXOI2Gk",
    artist_url: "https://www.facebook.com/phamsaclenh97",
  },
  {
    id: 2,
    image: "/img/music/colieu.jpg",
    name: "CÔ LIÊU",
    artist: "PHẠM SẮC LỆNH",
    url: "https://www.youtube.com/watch?v=S042dZnqe0w",
    artist_url: "https://www.facebook.com/phamsaclenh97",
  },
  {
    id: 3,
    image: "/img/music/lnmd2.jpg",
    name: "MÌNH LẠC NHAU MUÔN ĐỜI",
    artist: "PHẠM SẮC LỆNH",
    url: "https://www.youtube.com/watch?v=MAJGfBD4fUs",
    artist_url: "https://www.facebook.com/phamsaclenh97",
  },
  {
    id: 4,
    image: "/img/music/mmkce.jpg",
    name: "MAY MẮN KHI CÓ EM",
    artist: "ĐẠT VILLA",
    url: "https://www.youtube.com/watch?v=c5PhM-bDrkc",
    artist_url: "https://www.facebook.com/datvilla94",
  },
  {
    id: 5,
    image: "/img/music/sgcdal2.jpg",
    name: "SÓNG GIÓ CỨ ĐỂ ANH LO ",
    artist: "ĐẠT VILLA",
    url: "https://www.youtube.com/watch?v=4IfoEi7O_0Q",
    artist_url: "https://www.facebook.com/datvilla94",
  },
  {
    id: 6,
    image: "/img/music/dpma2.jpg",
    name: "ĐƠN PHƯƠNG MÌNH ANH",
    artist: "LINH TUỔI THÌN",
    url: "https://www.youtube.com/watch?v=SNts5pPOwBk",
    artist_url: "https://www.facebook.com/LQmedia73",
  },
];
