import React from 'react';

const OurServices = () => {
  return (
    <div data-v-76f4fb16="" data-v-3aa51a22="" class="our-service-container">
        <div data-v-76f4fb16="" class="ourservice-item left">
            <h1 data-v-76f4fb16="" class="title">Our Services</h1>
            <br data-v-76f4fb16=""/> 
            <p data-v-76f4fb16="" class="text">
            Currently, we are providing 360 services and solutions to our partners
                Collaboration includes: production, distribution, marketing, exploitation and trade, optimization
                culture, management and career orientation (music style, image).
                When cooperating with us, partners will receive policies and procedures
                benefits below.
            </p> 
            <button data-v-76f4fb16="" type="button" class="btn btn-service">
                <img data-v-76f4fb16="" src="/_nuxt/img/Group 39292.c79e7a1.png" alt=""/>Scroll down
            </button> 
            <div data-v-76f4fb16="">
                <img data-v-76f4fb16="" src="/_nuxt/img/Vector.6604bcb.png" alt="" class="wave-line"/>
            </div>
        </div> 
        <div data-v-76f4fb16="" class="ourservice-item right">
            <div data-v-76f4fb16="">
                <img data-v-76f4fb16="" src="/_nuxt/img/Girl listens to music.6fbdc77.png" alt="" class="image"/>
            </div>
        </div>
    </div>
  );
};

export default OurServices;