import React from "react";

const Process = () => {
    return (
        <div className="process-container" data-v-575b4f3a>
        <div className="line" data-v-575b4f3a>
          <img src="/_nuxt/img/Divider.64e9d59.png" alt="" data-v-575b4f3a />
        </div>
        <div className="process-tile" data-v-575b4f3a>
          <h2 data-v-575b4f3a>Product release process</h2>
        </div>
        <div className="process-content" data-v-575b4f3a>
          <div className="process-pc" data-v-575b4f3a>
            <img src="/_nuxt/img/Quy trinhf.aa23261.png" alt="" data-v-575b4f3a />
          </div>
          <div className="process-mobile" data-v-575b4f3a>
            <div className="process-item" data-v-575b4f3a>
              <img src="/_nuxt/img/1.2217d7f.png" alt="" data-v-575b4f3a />
            </div>
            <div className="process-item" data-v-575b4f3a>
              <img src="/_nuxt/img/2.384e5bc.png" alt="" data-v-575b4f3a />
            </div>
            <div className="process-item" data-v-575b4f3a>
              <img src="/_nuxt/img/3.e35d30f.png" alt="" data-v-575b4f3a />
            </div>
            <div className="process-item" data-v-575b4f3a>
              <img src="/_nuxt/img/4.563d449.png" alt="" data-v-575b4f3a />
            </div>
            <div className="process-item" data-v-575b4f3a>
              <img src="/_nuxt/img/5.e8c53b1.png" alt="" data-v-575b4f3a />
            </div>
            <div className="process-item" data-v-575b4f3a>
              <img src="/_nuxt/img/6.78979d8.png" alt="" data-v-575b4f3a />
            </div>
          </div>
        </div>
      </div>
    );
};

export default Process;