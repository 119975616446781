import React from "react";

const Partner = () => {
    return (
        <div className="partner-container" data-v-7c653112>
            <div className="line" data-v-7c653112>
            <img src="/_nuxt/img/Divider.64e9d59.png" alt="" data-v-7c653112 />
            </div>
            <div className="partner-content" data-v-7c653112>
            <h2 data-v-7c653112>Our partners</h2>
            </div>
            <div className="container" data-v-7c653112>
                <div
                    className="row row-cols-2 row-cols-md-3 row-cols-lg-5 g-2 g-lg-3 justify-content-center"
                    data-v-7c653112
                >
                    <div className="col" data-v-7c653112>
                    <a href="https://euphoriamedia.com/" data-v-7c653112>
                        <img
                        src="/img/partner/euphoria.png"
                        alt="euphoria"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://mixus.asia/" data-v-7c653112>
                        <img
                        src="/img/partner/mixus.png"
                        alt="mixus"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://www.facebook.com/thientucodocvuong" data-v-7c653112>
                        <img
                        src="/img/partner/thientu.png"
                        alt="thientu"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://www.facebook.com/longhaisinger" data-v-7c653112>
                        <img
                        src="/img/partner/htmmedia.png"
                        alt="htmmedia"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://www.youtube.com/channel/UCREwXigjpvbv_GYUrd6QVtg" data-v-7c653112>
                        <img
                        src="/img/partner/nhatdoan.png"
                        alt="nhatdoan"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://bhmedia.vn/" data-v-7c653112>
                        <img
                        src="/img/partner/bhmedia.png"
                        alt="bhmedia"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://zingmp3.vn" data-v-7c653112>
                        <img
                        src="/img/partner/zing mp3.png"
                        alt="Zing Mp3"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://www.nhaccuatui.com" data-v-7c653112>
                        <img
                        src="/img/partner/nct.png"
                        alt="Nhaccuatui"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://www.vcpmc.org" data-v-7c653112>
                        <img
                        src="/img/partner/vcpmc.png"
                        alt="VCPMC"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partner;